@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

* {
	box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Muli', Helvetica, sans-serif;
  overflow: hidden;
}

.header {
  background-color: transparent;
  color: black;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 12px 14px;
  justify-content: center;
  align-items: flex-start;
}

.map {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.location-icon {
  font-size: 1rem;
  opacity: 0.6;
}
.location-icon.active {
  color: #0083F5 !important;
  opacity: 1;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loader h1 {
  margin-top: -100px;
}

.location-marker {
  cursor: pointer;
}

.location-info {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(100vw - 24px);
  max-width: 400px;
  padding: 0 12px;
  font-size: 13px;
  background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.location-info h3 {
  margin-bottom: 0;
}

.location-info ul {
  list-style-type: none;
  padding: 0;
}

.location-info li {
  padding: 2px 0;
}

.msl-vars {
  background-color: rgba(255,255,255,0.7);
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.4em;
  cursor: pointer;
}
